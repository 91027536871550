import React from "react";
import { Intro, About, Services } from './';

/**
 * Home - The Home page component. It includes the Intro, About, and ServicesDetails components.
 * @returns {React.Component} The rendered component.
 */
const Home = () => (
  <div className="Home">
    <Intro />
    <About />
    <Services />
  </div>
);

export default Home;