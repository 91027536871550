import React, { useState } from "react";
import "../styles/privacypolicy.css";

/**
 * ContentSection - A reusable section for showing policy content.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.id - The unique identifier for the section.
 * @param {string} props.title - The title for the section.
 * @param {React.ReactNode} props.children - The children elements passed to the section.
 * @returns {React.Element} The rendered section element.
 */
let ContentSection = ({ id, title, children }) => (
  <section id={id}>
    {title && <h2>{title}</h2>}
    {children}
  </section>
);

let AcceptanceOverlay = ({ onAccept }) => {
  return (
    <div className="acceptanceOverlay">
      <div className="acceptanceContent">
        <p>By using our services, you accept our Privacy Policy and Terms of Service.</p>
        <button onClick={onAccept}>I Accept</button>
      </div>
    </div>
  );
};

/**
 * PrivacyPolicy - The main privacy policy page component.
 * It handles the acceptance state and displays policy information.
 * @returns {React.Element} The rendered privacy policy page.
 */
const PrivacyPolicy = () => {
  const [hasAccepted, setHasAccepted] = useState(false);

  const handleAcceptance = () => {
    setHasAccepted(true);
  };

  return (
    <div id="privacy-policy-page">
      {!hasAccepted && <AcceptanceOverlay onAccept={handleAcceptance} />}

      <header className="intro-policy" role="banner">
        <nav>
          <a href="#policy-intro" className="skip-link">Skip to policy intro</a>
          {/* Add rest of the links if needed */}
        </nav>

        <div className="intro-policy-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1 className="policy-brand-heading">Privacy Policy for SerityOps Innovations</h1>
                <p className="intro-text">Effective Date: March 10, 2024</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <ContentSection id="policy-intro">
          <p>Welcome to SerityOps Innovations, LLC ("SerityOps", "we", "us", or "our").
            Our applications and services, including CodeSentinel, VA Claims Assistant,
            and SerityOps Assistant (collectively, the “ServicesDetails”), are designed
            to enhance your digital interactions by leveraging advanced Generative
            Pre-trained Transformers (GPTs) and AI assistants hosted on OpenAI platforms.
            This Privacy Policy outlines our commitment to your privacy,
            detailing how we collect, use, share, and protect your information.</p>
        </ContentSection>

        <ContentSection id="policy-sections" title="1. Information We Collect">
          <ul>
            <li>Personal Data: We collect personal information such as your name and email address that you voluntarily provide when engaging with our ServicesDetails.</li>
            <li>User-Provided Data: Includes attachments, code, or data you submit while using our ServicesDetails.</li>
            <li>Derivative Data: Automatically collected information when you access our ServicesDetails, such as IP address, browser type, and usage details.</li>
          </ul>
        </ContentSection>

        <ContentSection title="2. Use of Your Information">
          <ul>
            <li>Deliver, operate, and maintain our ServicesDetails;</li>
            <li>Improve, personalize, and expand our ServicesDetails;</li>
            <li>Analyze usage patterns;</li>
            <li>Communicate with you for customer service and updates;</li>
            <li>Process transactions;</li>
            <li>Prevent fraudulent or illegal activities.</li>
          </ul>
        </ContentSection>

        <ContentSection title="3. Sharing Your Information">
          <ul>
            <li>With Legal Authorities: When required by law;</li>
            <li>During Business Transfers;</li>
            <li>With Third-Party Service Providers: For service operation purposes.</li>
          </ul>
        </ContentSection>

        <ContentSection title="4. Tracking Technologies">
          <p>We use cookies, web beacons, and other tracking technologies to improve your experience and personalize the ServicesDetails.</p>
        </ContentSection>

        <ContentSection title="5. Third-Party Websites and ServicesDetails">
          <p>Our ServicesDetails may link to third-party websites or use services hosted by third parties like OpenAI.
            Interaction with these services is subject to their privacy policies. Specifically, the functionality of our
            ServicesDetails relies on platforms provided by OpenAI, and thus, data processing adheres to
            <a href="https://openai.com/policies/privacy-policy"
               aria-label="OpenAI Privacy Policy in a new tab"
               target="_blank"
               rel="noopener noreferrer"> OpenAI's Privacy Policy</a>.
          </p>
        </ContentSection>

        <ContentSection title="6. Security of Information">
          <p>We employ various protective measures aimed at securing your personal information, though we advise that no system is entirely infallible.</p>
        </ContentSection>

        <ContentSection title="7. Children’s Privacy">
          <p>We do not knowingly collect information from children under 13 and will take steps to promptly delete it if such collection is discovered.</p>
        </ContentSection>

        <ContentSection title="8. Changes to This Privacy Policy">
          <p>We may update our Privacy Policy periodically, with the revised version taking effect upon posting. We encourage you to review our policy regularly.</p>
          <p>Users will receive an email when significant changes are made to the Privacy Policy or can check back on this page at any time.</p>
        </ContentSection>

        <ContentSection title="9. Contact Us">
          <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@serityops.com">privacy@serityops.com</a></p>
        </ContentSection>

      </main>
    </div>
  );
};
export default PrivacyPolicy;