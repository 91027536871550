import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/servicepage.css";

const servicesPageDetails = {
  applicationDevelopment: {
    title: 'Application Development',
    description: 'Building dynamic, scalable, and robust web and software applications, including Appstore-ready applications tailored to your specific business needs.',
    includedServices: [
      'Web Application Development',
      'Mobile Application Development (iOS and Android)',
      'Custom Software Development',
      'API Development and Integration',
      'E-commerce Solutions',
      'Progressive Web Apps (PWAs)',
    ],
    icon: 'tablet-alt'
  },
  codeDrivenInfrastructure: {
    title: 'Code-Driven Infrastructure Automation',
    description: 'Automating the management and provisioning of computing resources using Infrastructure as Code (IaC) to ensure consistency, efficiency, and scalability.',
    includedServices: [
      'Infrastructure as Code (IaC) Implementation',
      'Automated Deployment Pipelines',
      'Configuration Management',
      'Continuous Integration and Continuous Deployment (CI/CD)',
      'Cloud Resource Management',
      'Environment Provisioning and Management',
    ],
    icon: 'code-branch'
  },
  cloudImplementation: {
    title: 'Cloud Implementation and Migration',
    description: 'Comprehensive cloud solutions, including migrating existing applications to cloud platforms, installing new applications, and optimizing cloud infrastructure.',
    includedServices: [
      'Cloud Strategy and Consulting',
      'Cloud Migration Services',
      'Hybrid Cloud Solutions',
      'Cloud-Native Application Development',
      'Cloud Security and Compliance',
      'Cost Optimization and Management',
    ],
    icon: 'cloud'
  },
  websiteDesign: {
    title: 'Website Design',
    description: 'Our approach to website design prioritizes a seamless user experience, marrying aesthetics, usability, and accessibility to distinguish your digital identity.',
    includedServices: [
      'Responsive Design ensuring optimal viewing across devices',
      'SEO Optimization for maximum online visibility',
      'Brand Integration that resonates with your corporate identity',
      'User-Centric Design for engaging user experiences',
      'Accessibility Compliance to reach a wider audience'
    ],
    icon: 'paintbrush'
  },
  itConsulting: {
    title: 'IT Consulting',
    description: 'Offering comprehensive advice on leveraging technology to grow and enhance operations, ensuring smooth, efficient workflows and strategic decision-making.',
    includedServices: [
      'Technology Roadmap Development',
      'IT Strategy and Planning',
      'Business Process Optimization',
      'Digital Transformation Consulting',
      'Cybersecurity Assessment and Planning',
      'Regulatory Compliance Consulting',
    ],
    icon: 'user-tie'
  },
  customSoftwareSolutions: {
    title: 'Custom Software Solutions',
    description: 'Creating tailored software solutions to tackle unique challenges, perfectly fitting our clients\' specifications and driving business innovation.',
    includedServices: [
      'Bespoke Software Development',
      'Enterprise Application Integration',
      'Legacy System Modernization',
      'Software Maintenance and Support',
      'Performance Optimization',
      'Security and Compliance',
    ],
    icon: 'laptop-code'
  },
};

const ServicesPageCard = ({ detail }) => (
  <div className="service-page-card">
    <div className="icon-and-services">
      <FontAwesomeIcon icon={['fas', detail.icon]} className="service-icon" size="6x" />
      <div>
        <h2>{detail.title}</h2>
        <p>{detail.description}</p>
        <ul className="services-list">
          {detail.includedServices.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const ServicesPage = () => (
  <section id="service-page-details">
    <div className="container">
      {Object.values(servicesPageDetails).map((detail, index) => (
        <ServicesPageCard key={index} detail={detail} />
      ))}
    </div>
  </section>
);

export default ServicesPage;
