import React, { useEffect, useState } from "react";
import "../styles/preloader.css"
const Preloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500); // Or whatever timing you want
    return () => clearTimeout(timer);
  }, []);

  if (!loading) return null;

  return (
    <div id="preloader">
      <div className="loader">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Preloader;
