import React from "react";
import "../styles/intro.css";

const Intro = () => {
  return (
    <section className="intro-section">
      <div className="intro-text-box">
        <h1 className="brand-heading">SerityOps Innovations</h1>
        <p className="intro-text">
          Empowering your business with reliable tech solutions that simplify your path to digital excellence.
        </p>
      </div>
    </section>
  );
};

export default Intro;