import React from "react";
import { Link } from "react-router-dom";

import "../App.css";
import "../styles/footer.css";
import companyIcon from "../assets/images/footer_icon.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedinIn,
  faXTwitter,
  faFacebook,
} from "@awesome.me/kit-e790b06899/icons/classic/brands";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <Link to="/"  className="footer-logo">
          <img
            src={companyIcon}
            alt="SerityOps Innovations Icon"
          />
        </Link>

        <div className="footer-socials">
          <a
            href="https://www.instagram.com/ComingSoon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a
            href="https://www.twitter.com/ComingSoon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter} size="2x" />
          </a>
          <a
            href="https://www.facebook.com/ComingSoon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x"/>
          </a>
          <a
            href="https://www.linkedin.com/ComingSoon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </a>
        </div>

        <div className="footer-service-link">
          <Link to="/services" title="Learn more about our services">
            Discover Our Services
          </Link>
        </div>

        <div className="footer-contact">
          <a href="mailto:info@serityops.com">Contact Us</a>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-copy">
          <p>
            © {new Date().getFullYear()} SerityOps Innovations, LLC. All rights
            reserved.
          </p>
        </div>

        <div className="footer-legal">
          <Link to="/privacy-policy"> Privacy Policy  </Link>
          |
          <Link to="/terms-conditions">  Terms & Conditions </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;