import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/services.css";

/**
 * Data for the service offerings.
 */
export const servicesData = [
  {
    title: "Application Development",
    description: "Building dynamic, scalable, and robust web and software applications, including Appstore-ready applications.",
    icon: "tablet-screen-button",
    detailsPath: "/services"
  },
  {
    title: "Code-Driven Infrastructure Automation",
    description: "Automating the management and provisioning of computing resources using Infrastructure as Code (IaC) for consistency and efficiency.",
    icon: "laptop-code",
    detailsPath: "/services"
  },
  {
    title: "Cloud Implementation and Migration",
    description: "Comprehensive cloud solutions, including migrating existing applications to cloud platforms, installing new applications, and optimizing cloud infrastructure.",
    icon: "cloud",
    detailsPath: "/services"
  },
];

/**
 * Represents a card for an individual service.
 */
const ServiceCard = ({ icon, title, description, detailsPath }) => (
  <div className="service-card">
    <div className="icon-wrapper">
      <FontAwesomeIcon icon={["fas", icon]} size="3x" />
    </div>
    <div className="card-body">
      <h3 className="card-title">{title}</h3>
      <p className="card-text">{description}</p>
      <button className="learn-more-btn" onClick={() => window.location.href = detailsPath}>Learn More</button>
    </div>
  </div>
);

/**
 * The services section of the website, providing an overview of the main services offered.
 */
const Services = () => {
  const navigate = useNavigate(); // Use the useNavigate hook

  // Function to navigate to the get-started route
  const navigateToGetStarted = () => {
    navigate('/get-started'); // Use navigate function to redirect
  };
  return (
    <section id="services">
      <div className="container">
        <h2 className="section-heading">Empower Your Business with Our Expertise</h2>
        <div className="services-grid">
          {servicesData.map((service, index) => (
            <ServiceCard
              key={index}
              icon={service.icon}
              title={service.title}
              description={service.description}
              detailsPath={service.detailsPath}
            />
          ))}
        </div>
        <div className="services-cta">
          <button className="get-started-btn" onClick={navigateToGetStarted}>Get Started with Us</button>
        </div>
      </div>
    </section>
  );
};

export default Services;
