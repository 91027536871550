import React from 'react';
import '../styles/capstatement.css';

const CapabilitiesStatement = () => {
  return (
    <>
      <header className="statement-header" role="banner">
        <h1>Capabilities Statement</h1>
        <p>Empowering Individuals & Businesses Through Digital Transformation</p>
      </header>
      <div className="capabilities-statement">
        <div className="dual-column-container">
          <section className="business-info section-card">
            <h2>Business Identification</h2>
            <ul>
              <li><strong>Socio-Economic Certifications:</strong></li>
              <ul className="nested-list">
                <li>Service-Disabled Veteran-Owned Small Business (SDVOSB)</li>
                <li>Minority-Owned</li>
                <li>Woman-Owned</li>
              </ul>
              <li><strong>Business Size:</strong> Micro / Small Business</li>
              <li><strong>CAGE Code:</strong> 9T3E8</li>
              <li><strong>SAM Unique Entity ID (UEI):</strong> L5PJSLA7PR45</li>
            </ul>
          </section>

          <section className="differentiators section-card">
            <h2>Competitive Differentiators</h2>
            <ul>
              <li>Expertise in COTS and SaaS enterprise implementation and integration.</li>
              <li>Focused on interoperability, governance, and DevSecOps best practices.</li>
              <li>Impactful technology solutions with societal benefits.</li>
              <li>Custom-tailored services that align with client-specific requirements.</li>
              <li>Adaptable and scalable delivery models for services.</li>
              <li>Unwavering dedication to delivering cost-effective, high-quality solutions.</li>
            </ul>
          </section>
        </div>

        <div className="dual-column-container">
          <section className="core-competencies section-card">
            <h2>Core Competencies</h2>
            <ul>
              <li>COTS and SaaS Enterprise Implementation</li>
              <li>Cloud Implementation and Migration</li>
              <li>Interoperability and IT Governance</li>
              <li>Advanced Cloud Computing Services</li>
              <li>DevSecOps Best Practices</li>
              <li>Code-Driven Infrastructure Automation</li>
              <li>Agile Project Management for Operational Efficiency</li>
              <li>Responsive Single Page Application & Interactive Website Design</li>
              <li>Strategic Data Analysis for Business Intelligence</li>
            </ul>
          </section>

          <section className="naics-codes section-card">
            <h2>Primary NAICS Code</h2>
            <p><strong>541511</strong> - Custom Computer Programming Services</p>
            <h3>Additional NAICS Codes</h3>
            <ul>
              <li><strong>518210</strong> - Data Processing, Hosting, and Related Services</li>
              <li><strong>541512</strong> - Computer Systems Design Services</li>
              <li><strong>541519</strong> - Other Computer Related Services</li>
              <li><strong>541611</strong> - Administrative Management & General Management Consulting Services</li>
              <li><strong>541690</strong> - Other Scientific and Technical Consulting Services</li>
            </ul>
          </section>
        </div>

        <section className="vision-for-partnership section-card">
          <h2>Partnership and Growth</h2>
          <p>
            Our partnership philosophy centers around collaborative innovation and shared growth. We tackle the unique challenges faced by our clients with bespoke solutions that exceed expectations and set new industry standards.
          </p>
        </section>

        <section className="contact-us section-card">
          <h2>Connect with SerityOps Innovations</h2>
          <p>
            Join us on a transformative journey that champions operational efficiency and societal advancement. Explore opportunities for pioneering partnerships with SerityOps Innovations.
          </p>
          <p>
            <a href="https://dsbs.sba.gov/search/dsp_profile.cfm?SAM_UEI=L5PJSLA7PR45" target="_blank" rel="noopener noreferrer">
              Visit our SBA DSBS Profile
            </a>
          </p>
        </section>
      </div>
    </>
  );
};

export default CapabilitiesStatement;
