import React from "react";

/**
 * NotFound - The component to be rendered when the user navigates to an undefined path.
 * @returns {React.Component} The rendered component.
 */
const NotFound = () => (
  <div className="NotFound">
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you tried cannot be found.</p>
  </div>
);

export default NotFound;