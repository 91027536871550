import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import companyLogo from "../assets/images/serityops_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCaretDown } from "@awesome.me/kit-e790b06899/icons/classic/solid";
import "../styles/navbar.css";

const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(() => setIsDropdownOpen(false), 0); // Introduce delay
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="navbar" role="navigation">
      <Link to="/" className="navbar-logo">
        <img src={companyLogo} alt="SerityOps Innovations Logo" />
      </Link>
      <button
        className={`navbar-toggler ${isDropdownOpen ? "active" : ""}`}
        onClick={toggleDropdown}
        type="button"
        aria-expanded={isDropdownOpen} // Indicates the state of the dropdown to assistive technologies
      >
        <FontAwesomeIcon icon={faCircleCaretDown} aria-hidden="true" />
      </button>

      {isDropdownOpen && (
        <div className="navbar-dropdown" ref={dropdownRef}>  {/* Attach the ref here */}
          <Link to="/get-started" className="dropdown-item">
            Schedule Consultation
          </Link>
          <Link to="/services" className="dropdown-item">
            Our Services
          </Link>
          <Link to="/capabilities-statement" className="dropdown-item">
            Capabilities Statement
          </Link>
          {/* Additional links can be added here */}
        </div>
      )}
    </nav>
  );
};

export default NavBar;