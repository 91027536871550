import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components
import {
  Layout,
  Home,
  PrivacyPolicy,
  Terms,
  ServicesPage,
  GetStarted,
  CapabilitiesStatement,
  NotFound404
} from "./components";

import "./App.css";

/**
 * App - The main app component responsible for routing.
 * It contains different routes for Home, Privacy Policy and a NotFound page for undefined routes.
 * @returns {React.Element} the rendered element
 */
function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/capabilities-statement" element={<CapabilitiesStatement />} />
          <Route path="/error-404" element={<NotFound404 />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;