import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmileBeam, faGrinStars } from "@awesome.me/kit-e790b06899/icons/classic/solid";
import '../styles/getstarted.css';

const GetStarted = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let apiUrl = 'https://3u1u0q2es1.execute-api.us-east-1.amazonaws.com/dev';

    try {
      console.log('Preparing to send data:', formData);

      await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'form_data': formData }),
      });

      console.log('Request sent. Assuming success regardless of actual outcome.');

    } catch (error) {
      console.error('Failed to submit form:', error);
      // Optionally, set an error state here to show an error message
    } finally {
      setIsSubmitting(false); // Reset submission status
      setSubmitted(true); // Assume success and update state accordingly
      setFormData({ name: '', email: '', message: '' }); // Reset form data
    }
  };

  return (
    <div className="get-started-card">
      <FontAwesomeIcon icon={submitted ? faGrinStars : faSmileBeam} size="3x" className={`get-started-icon ${!submitted && 'fa-beat'}`} />
      {!submitted ? (
        <>
          <h1 className="get-started-title">Schedule a Consultation</h1>
          <p className="get-started-text">
            Take the first step towards empowering your business with our expert guidance and innovative solutions.
            Fill out the form below to schedule a consultation with our specialists.
          </p>
          <form className="get-started-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Describe your project or inquiry"
              required
            ></textarea>
            <button type="submit" disabled={isSubmitting} className={isSubmitting ? "submitting-request" : "submit-btn"}>
              {isSubmitting ? "Submitting Request..." : "Submit"}
            </button>
          </form>
        </>
      ) : (
        <>
          <h1 className="get-started-title">Inquiry Submitted</h1>
          <p className="submission-confirmation">
            Thank you for your submission! We will be in touch soon.
          </p>
        </>
      )}
    </div>
  );
};

export default GetStarted;
