import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// FontAwesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { all } from "@awesome.me/kit-e790b06899/icons";

library.add(...all);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Define a function to handle the results
const logWebVitals = ({ id, name, value }) => {
  console.log("Web Vitals", id, name, value);
  // Here you could send the results to a backend endpoint for analysis
  // Example:
  // fetch('https://your-backend-service.com/analytics', {
  //   method: 'POST',
  //   body: JSON.stringify({ id, name, value }),
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logWebVitals);
