import React from "react";
import "../styles/termsconditions.css";

const ContentSection = ({ id, title, children }) => (
  <section id={id}>
    {title && <h2>{title}</h2>}
    {children}
  </section>
);


const TermsConditions = () => {
  return (
    <div id="terms-conditions-page">
      <header className="intro-policy" role="banner">
        <div className="intro-policy-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1 className="policy-brand-heading">Terms and Conditions for SerityOps Innovations</h1>
                <p className="intro-text">Please read these terms and conditions carefully.</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <ContentSection id="terms-intro" title="Introduction">
          <p>Welcome to SerityOps Innovations! These Terms and Conditions outline the rules and regulations for the use of SerityOps Innovations' Website. By accessing this website, we assume you accept these terms and conditions. Do not continue to use SerityOps Innovations if you do not agree to all of the terms and conditions stated on this page.</p>
        </ContentSection>

        <ContentSection id="terms-intellectual-property" title="Intellectual Property Rights">
          <p>Other than the content you own, under these Terms, SerityOps Innovations and/or its licensors own all the intellectual property rights and materials contained in this Website. You are granted a limited license only for purposes of viewing the material contained on this Website. The tools, products, and services provided by SerityOps Innovations encompass various algorithms, methodologies, and designs that are proprietary to the company. Replicating, distributing, or commercializing the content without appropriate permission is prohibited.</p>
        </ContentSection>

        <ContentSection id="terms-responsibilities" title="User Responsibilities">
          <ul>
            <li>Publishing any Website material in any other media without prior written consent.</li>
            <li>Selling, sublicensing, and/or otherwise commercializing any Website material.</li>
            <li>Publicly performing and/or showing any Website material.</li>
            <li>Using this Website in any way that is or may be damaging to this Website or to SerityOps Innovations' reputation.</li>
            <li>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website.</li>
          </ul>
          <p>Your access to SerityOps Innovations and its services is conditional upon your adherence to these responsibilities. Failure to comply may result in termination of your access to the website and its services.</p>
        </ContentSection>

        <ContentSection id="terms-privacy" title="Privacy Policy">
          <p>The privacy and security of your data are of paramount importance to SerityOps Innovations. Please refer to our Privacy Policy, which explains how we collect, use, protect, and disclose information and data when you use the SerityOps Innovations Website and services. By using our services, you consent to the data practices described in our Privacy Policy.</p>
        </ContentSection>

        <ContentSection id="terms-liability" title="Limitation of Liability">
          <p>In no event shall SerityOps Innovations, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. SerityOps Innovations, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.</p>
        </ContentSection>

        <ContentSection id="terms-law" title="Governing Law">
          <p>These Terms will be governed by and interpreted in accordance with the laws of the State where SerityOps Innovations is registered, without regard to its conflict of law provisions. You submit to the non-exclusive jurisdiction of the state and federal court in that State for the resolution of any disputes.</p>
        </ContentSection>
      </main>
    </div>
  );
};

export default TermsConditions;