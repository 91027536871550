import React from "react";
import veteranLogo from "../assets/images/sdvoc.png";
import "../styles/about.css";
const About = () => {
  return (
    <section id="about-us" className="about-section">
      <div className="container">
        <h2 className="section-heading">Empowering Your Tech Journey</h2>
        <div className="about-content">
          <p>
            At SerityOps Innovations, we understand that technology is the
            heartbeat of modern business. We're not just a company; we're your
            strategic partner in navigating the complexities of cloud computing,
            DevSecOps, and infrastructure automation. We're here to transform
            your challenges into triumphs.
          </p>
          <div className="about-highlights">
            <div className="highlight">
              <h3>Our Commitment</h3>
              <p>
                We pledge to deliver cutting-edge solutions tailored to each
                unique aspect of your operations, fostering efficiency, agility,
                and growth.
              </p>
            </div>
            <div className="highlight">
              <h3>Innovative Solutions</h3>
              <p>
                Our team brings forward-thinking strategies and state-of-the-art
                digital transformation solutions that align with your business
                goals.
              </p>
            </div>
            <div className="highlight">
              <h3>Expert Guidance</h3>
              <p>
                Leverage our expertise to gain a competitive edge—innovate,
                optimize, and scale with confidence.
              </p>
            </div>
          </div>
          {/* Optionally, include a section for each founder or team member with a picture and a short bio */}
        </div>
      </div>
      <div className="veteran-logo-box">
        <img
          src={veteranLogo}
          alt="Service-Disabled Veteran-Owned Certified Logo"
          className="veteran-logo"
        />
      </div>
    </section>
  );
};

export default About;