import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/serityops_logo.png"
import "../styles/layout.css"

// Import components
import {
  Preloader,
  NavBar,
  ReturnToTop,
  Footer,
} from "./";

/**
 * Layout - The layout component which contains the common structure for all pages.
 * @param {React.ReactNode} children - The children components to be wrapped within the layout.
 * @returns {React.Element} the rendered element
 */
const Layout = ({ children }) => (
  <>
    <Preloader/>
    <NavBar>
      <Link to="/"><img src={logo} alt="SerityOps Company Logo" /></Link>
    </NavBar>
    <ReturnToTop/>
    <main className="main-children">{children}</main> {/* Wrap children in main tag */}
    <Footer />
  </>
);

export default Layout;